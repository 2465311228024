<template>
    <svg
        class="block h-10 w-10 md:hidden"
        viewBox="0 0 12.7 12.7"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g transform="translate(-73.025 -73.025)">
            <g fill="#f59300">
                <path
                    d="M78.846 73.978l-1.323.926-1.59.28-.749.75-.28 1.59-.926 1.322v1.059l.926 1.323.28 1.59.749.748 1.59.28 1.323.927h1.058l1.323-.926 1.59-.28.749-.749.28-1.59.927-1.323v-1.059l-.927-1.323-.28-1.59-.748-.748-1.59-.28-1.324-.927zm0 .47v1.048a3.916 3.916 0 0 1 .53-.036 3.916 3.916 0 0 1 .528.036v-1.049l1.173.821 1.409.248-.741.742a3.916 3.916 0 0 1 .748.748l.741-.741.249 1.41.82 1.171h-1.048a3.916 3.916 0 0 1 .036.53 3.916 3.916 0 0 1-.036.529h1.049l-.821 1.172-.249 1.41-.741-.742a3.916 3.916 0 0 1-.748.748l.74.741-1.408.249-1.173.82v-1.048a3.916 3.916 0 0 1-.529.036 3.916 3.916 0 0 1-.529-.036v1.049l-1.172-.821-1.41-.249.742-.74a3.916 3.916 0 0 1-.749-.75l-.74.742-.25-1.41-.82-1.171h1.048a3.916 3.916 0 0 1-.036-.53 3.916 3.916 0 0 1 .036-.529h-1.048l.82-1.172.25-1.41.74.742a3.916 3.916 0 0 1 .749-.748l-.741-.742 1.409-.248zM79.798 73.448a.423.423 0 0 1-.423.423.423.423 0 0 1-.424-.423.423.423 0 0 1 .424-.423.423.423 0 0 1 .423.423M79.798 85.302a.423.423 0 0 0-.423-.423.423.423 0 0 0-.424.423.423.423 0 0 0 .424.423.423.423 0 0 0 .423-.423M83.865 75.484a.423.423 45 0 1-.599 0 .423.423 45 0 1 0-.599.423.423 45 0 1 .599 0 .423.423 45 0 1 0 .599M75.484 83.865a.423.423 45 0 0 0-.599.423.423 45 0 0-.599 0 .423.423 45 0 0 0 .599.423.423 45 0 0 .599 0M85.302 79.798a.423.423 0 0 1-.423-.423.423.423 0 0 1 .423-.424.423.423 0 0 1 .423.424.423.423 0 0 1-.423.423M73.448 79.798a.423.423 0 0 0 .423-.423.423.423 0 0 0-.423-.424.423.423 0 0 0-.423.424.423.423 0 0 0 .423.423M74.885 75.484a.423.423 45 0 0 .599 0 .423.423 45 0 0 0-.599.423.423 45 0 0-.599 0 .423.423 45 0 0 0 .599M83.266 83.865a.423.423 45 0 1 0-.599.423.423 45 0 1 .599 0 .423.423 45 0 1 0 .599.423.423 45 0 1-.599 0"
                />
            </g>
            <circle cx="79.375" cy="79.375" r="3.81" fill="#fff" />
            <path
                transform="matrix(.08615 0 0 .08654 72.537 72.446)"
                d="M79.375 44.979l-12.7 7.938h4.233v9.524H55.033L50.8 78.316h4.233V98.16H44.98v5.028h68.792V98.16h-10.054V78.316h4.234l-4.234-15.875H87.842v-9.525h4.234z"
                fill="#60a3be"
            />
            <path
                transform="matrix(.02117 0 0 .02117 62.907 71.729)"
                d="M778.6 325.25l-15.467 4.24c-8.508 2.332-15.469 4.782-15.469 5.444 0 .66 2.622 1.833 5.828 2.603l5.83 1.4.776 17.551c.842 19.015 1.91 21.693 10.104 25.336h.002c9.44 4.198 18.794 1.183 24.156-7.789 2.46-4.116 3.034-8 3.039-20.537.004-11.297.53-15.466 1.949-15.482 1.069-.012 3.516-.452 5.44-.978 3.313-.909 5.13-.354 3.497 7.95-1 5.085.728 9.252 1.442 8.905 1.416-.687.402-17.518-1.25-19.248-.533-.557-7.473-2.9-15.422-5.205zm-22.348 61.375c-.923.06-6.83.36-10.502 4.287-1.71 1.122-2.734 5.277-4.244 15.1-1.024 6.656-2.23 13.708-2.684 15.67l-.82 3.569h80l-.826-4.362c-.454-2.398-1.697-9.954-2.762-16.79-1.194-7.667-2.765-12.756-4.1-13.278-.036-.014-.067-.023-.103-.037-.57-1.09-2.547-3.565-8.959-4.002-8.25-.562-23.035 9.85-23.035 9.85s-11.027-6.162-14.527-7.756-6.469-2.313-7.437-2.25z"
                fill="#fff"
            />
        </g>
    </svg>
    <svg
        class="hidden h-10 w-auto fill-current text-white hover:fill-blue-300 md:block"
        version="1.1"
        viewBox="0 0 49.21 16.933"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:osb="http://www.openswatchbook.org/uri/2009/osb"
    >
        <g transform="translate(-206.07 -70.908)">
            <text
                fill="#000000"
                font-family="sans-serif"
                font-size="10.583px"
                style="
                    line-height: 1.25;
                    shape-inside: url(#rect1487);
                    white-space: pre;
                "
                xml:space="preserve"
            />
            <g fill="#f59300">
                <path
                    d="m213.83 72.178-1.7638 1.2351-2.1205 0.37389-0.99781 0.99781-0.37389 2.1205-1.235 1.7638v1.4111l1.235 1.7638 0.37389 2.1205 0.99781 0.99781 2.1205 0.37388 1.7638 1.2351h1.4111l1.7638-1.2351 2.1205-0.37388 0.99781-0.99781 0.37389-2.1205 1.235-1.7638v-1.4111l-1.235-1.7638-0.37389-2.1205-0.99781-0.99781-2.1205-0.37389-1.7638-1.2351zm0 0.62552v1.3979a5.2211 5.2211 0 0 1 0.70556-0.0479 5.2211 5.2211 0 0 1 0.70555 0.0479v-1.3979l1.563 1.0944 1.879 0.33134-0.98844 0.98844a5.2211 5.2211 0 0 1 0.99781 0.99781l0.98844-0.98844 0.33134 1.879 1.0944 1.563h-1.3979a5.2211 5.2211 0 0 1 0.0479 0.70555 5.2211 5.2211 0 0 1-0.0479 0.70556h1.3979l-1.0944 1.563-0.33129 1.8791-0.98849-0.98849a5.2211 5.2211 0 0 1-0.99781 0.99781l0.98844 0.98843-1.879 0.33134-1.563 1.0944v-1.3979a5.2211 5.2211 0 0 1-0.70555 0.0479 5.2211 5.2211 0 0 1-0.70556-0.0479v1.3979l-1.563-1.0944-1.879-0.33134 0.98844-0.98843a5.2211 5.2211 0 0 1-0.99781-0.99781l-0.98844 0.98844-0.33133-1.879-1.0944-1.563h1.3979a5.2211 5.2211 0 0 1-0.0479-0.70556 5.2211 5.2211 0 0 1 0.0479-0.70555h-1.3979l1.0944-1.563 0.33133-1.879 0.98844 0.98844a5.2211 5.2211 0 0 1 0.99781-0.99781l-0.98844-0.98844 1.879-0.33134z"
                />
                <g>
                    <path
                        d="m215.1 71.473a0.56444 0.56444 0 0 1-0.56444 0.56444 0.56444 0.56444 0 0 1-0.56445-0.56444 0.56444 0.56444 0 0 1 0.56445-0.56445 0.56444 0.56444 0 0 1 0.56444 0.56445"
                    />
                    <path
                        d="m215.1 87.277a0.56444 0.56444 0 0 0-0.56444-0.56444 0.56444 0.56444 0 0 0-0.56445 0.56444 0.56444 0.56444 0 0 0 0.56445 0.56445 0.56444 0.56444 0 0 0 0.56444-0.56445"
                    />
                    <path
                        d="m220.52 74.186a0.56444 0.56444 45 0 1-0.79824 0 0.56444 0.56444 45 0 1 0-0.79825 0.56444 0.56444 45 0 1 0.79824 0 0.56444 0.56444 45 0 1 0 0.79825"
                    />
                    <path
                        d="m209.35 85.362a0.56444 0.56444 45 0 0 0-0.79825 0.56444 0.56444 45 0 0-0.79824 0 0.56444 0.56444 45 0 0 0 0.79825 0.56444 0.56444 45 0 0 0.79824 0"
                    />
                    <path
                        d="m222.44 79.939a0.56444 0.56444 0 0 1-0.56445-0.56445 0.56444 0.56444 0 0 1 0.56445-0.56444 0.56444 0.56444 0 0 1 0.56444 0.56444 0.56444 0.56444 0 0 1-0.56444 0.56445"
                    />
                    <path
                        d="m206.63 79.939a0.56444 0.56444 0 0 0 0.56445-0.56445 0.56444 0.56444 0 0 0-0.56445-0.56444 0.56444 0.56444 0 0 0-0.56444 0.56444 0.56444 0.56444 0 0 0 0.56444 0.56445"
                    />
                    <path
                        d="m208.55 74.186a0.56444 0.56444 45 0 0 0.79824 0 0.56444 0.56444 45 0 0 0-0.79825 0.56444 0.56444 45 0 0-0.79824 0 0.56444 0.56444 45 0 0 0 0.79825"
                    />
                    <path
                        d="m219.72 85.362a0.56444 0.56444 45 0 1 0-0.79825 0.56444 0.56444 45 0 1 0.79824 0 0.56444 0.56444 45 0 1 0 0.79825 0.56444 0.56444 45 0 1-0.79824 0"
                    />
                </g>
            </g>
            <circle cx="214.53" cy="79.375" r="5.08" fill="#fff" />
            <path
                transform="matrix(.11487 0 0 .11539 205.42 70.136)"
                d="m79.375 44.979-12.7 7.9375h4.2333v9.525h-15.875l-4.2333 15.875h4.2333v19.844h-10.054v5.0271h68.792v-5.0271h-10.054v-19.844h4.2333l-4.2333-15.875h-15.875v-9.525h4.2333z"
                fill="#60a3be"
            />
            <path
                transform="matrix(.028222 0 0 .028222 192.58 69.18)"
                d="m778.6 325.25-15.467 4.2402c-8.5075 2.3322-15.469 4.782-15.469 5.4434 0 0.66127 2.6221 1.833 5.8281 2.6035l5.8301 1.4004 0.77539 17.551c0.84195 19.015 1.9105 21.693 10.104 25.336h2e-3c9.4411 4.1975 18.794 1.1821 24.156-7.7891 2.4599-4.1162 3.0344-8.0012 3.0391-20.537 4e-3 -11.297 0.53008-15.466 1.9492-15.482 1.0687-0.012 3.516-0.45261 5.4395-0.97851 3.3138-0.90846 5.1306-0.3541 3.498 7.9492-1 5.086 0.72793 9.2526 1.4414 8.9062 1.4162-0.6875 0.40231-17.518-1.25-19.248-0.53259-0.55765-7.4723-2.9006-15.422-5.2051zm-22.348 61.375c-0.92289 0.0594-6.8301 0.36069-10.502 4.2871-1.7096 1.1221-2.7339 5.2773-4.2441 15.1-1.0242 6.6563-2.2301 13.708-2.6836 15.67l-0.82031 3.5684h80l-0.82617-4.3613c-0.45384-2.3984-1.6969-9.9539-2.7617-16.791-1.1942-7.6666-2.7649-12.755-4.0996-13.277-0.0367-0.0144-0.0675-0.0234-0.10352-0.0371-0.57074-1.09-2.5474-3.5648-8.959-4.002-8.25-0.56251-23.035 9.8496-23.035 9.8496s-11.027-6.1621-14.527-7.7559-6.4687-2.3125-7.4375-2.25z"
                fill="#fff"
                stroke-width="1.57"
            />
            <g
                transform="matrix(.10667 0 0 .10667 206.07 70.908)"
                stroke-width=".26458"
                aria-label="Success Together"
            >
                <path
                    d="m201.28 37.439q-0.46302-0.46302-1.7198-1.2568-1.1906-0.79375-2.9766-1.5214-1.7198-0.79375-3.7703-1.3229-2.0505-0.52917-4.1672-0.52917-3.7703 0-5.6224 1.3891t-1.8521 3.9026q0 1.4552 0.66145 2.4474 0.72761 0.92604 2.0505 1.6536 1.3229 0.7276 3.3073 1.3229 2.0505 0.59531 4.6964 1.2568 3.4396 0.92604 6.2177 1.9844 2.8443 1.0583 4.7625 2.6458 1.9844 1.5875 3.0427 3.8365 1.0583 2.1828 1.0583 5.424 0 3.7703-1.4552 6.4823-1.3891 2.6458-3.8365 4.2995-2.3812 1.6536-5.5562 2.4474-3.1089 0.7276-6.5484 0.7276-5.2917 0-10.451-1.5875-5.1594-1.5875-9.2604-4.4979l4.0349-7.8714q0.59531 0.59531 2.1167 1.5875 1.5875 0.92604 3.7042 1.9182 2.1167 0.92604 4.6964 1.5875t5.2917 0.66146q7.5406 0 7.5406-4.8286 0-1.5214-0.8599-2.5797t-2.4474-1.8521q-1.5875-0.79375-3.8365-1.4552t-5.0271-1.4552q-3.3734-0.92604-5.887-1.9844-2.4474-1.1245-4.101-2.5797-1.6536-1.5214-2.5135-3.4396-0.79375-1.9844-0.79375-4.6964 0-3.5719 1.3229-6.35 1.3229-2.7781 3.638-4.6302 2.3812-1.8521 5.4901-2.7781 3.1088-0.99219 6.6807-0.99219 4.9609 0 9.1281 1.5875 4.1672 1.5214 7.276 3.638z"
                />
                <path
                    d="m224.78 72.76q-5.3578 0-8.1359-3.4396-2.7781-3.4396-2.7781-10.186v-21.696h8.8635v19.778q0 8.0036 5.7547 8.0036 2.5797 0 4.9609-1.5214 2.4474-1.5875 3.9688-4.7625v-21.497h8.8635v24.474q0 1.3891 0.46302 1.9844 0.52917 0.59531 1.6536 0.66146v7.5406q-1.3229 0.26458-2.249 0.33073-0.85989 0.06615-1.5875 0.06615-2.3812 0-3.9026-1.0583-1.4552-1.1245-1.7198-3.0427l-0.19844-2.7781q-2.3151 3.5719-5.9531 5.3578-3.638 1.7859-8.0036 1.7859z"
                />
                <path
                    d="m254.03 54.769q0-3.638 1.2568-6.8792t3.638-5.6885q2.3812-2.4474 5.7547-3.9026 3.3734-1.4552 7.6068-1.4552 5.6885 0 9.6573 2.4474 4.0349 2.4474 6.0193 6.4161l-8.6651 2.6458q-1.1245-1.9182-2.9766-2.9104-1.8521-1.0583-4.101-1.0583-1.9182 0-3.5719 0.79375-1.6536 0.7276-2.9104 2.1167-1.1906 1.3229-1.9182 3.2411-0.66145 1.9182-0.66145 4.2333t0.7276 4.2333 1.9182 3.3073q1.2568 1.3891 2.9104 2.1828 1.6536 0.7276 3.5057 0.7276 2.3812 0 4.3656-1.1906 2.0505-1.1906 2.8443-2.9104l8.6651 2.6458q-1.7859 3.9687-5.887 6.4823-4.101 2.5135-9.8557 2.5135-4.2333 0-7.6068-1.4552-3.3734-1.4552-5.7547-3.9026-2.3812-2.5135-3.7042-5.7547-1.2568-3.3073-1.2568-6.8792z"
                />
                <path
                    d="m292.17 54.769q0-3.638 1.2568-6.8792t3.638-5.6885q2.3812-2.4474 5.7547-3.9026 3.3734-1.4552 7.6068-1.4552 5.6885 0 9.6573 2.4474 4.0349 2.4474 6.0193 6.4161l-8.6651 2.6458q-1.1245-1.9182-2.9766-2.9104-1.8521-1.0583-4.101-1.0583-1.9182 0-3.5719 0.79375-1.6536 0.7276-2.9104 2.1167-1.1906 1.3229-1.9182 3.2411-0.66146 1.9182-0.66146 4.2333t0.7276 4.2333q0.72761 1.9182 1.9182 3.3073 1.2568 1.3891 2.9104 2.1828 1.6536 0.7276 3.5057 0.7276 2.3812 0 4.3656-1.1906 2.0505-1.1906 2.8443-2.9104l8.6651 2.6458q-1.7859 3.9687-5.887 6.4823-4.101 2.5135-9.8557 2.5135-4.2333 0-7.6068-1.4552-3.3734-1.4552-5.7547-3.9026-2.3812-2.5135-3.7042-5.7547-1.2568-3.3073-1.2568-6.8792z"
                />
                <path
                    d="m348.57 72.76q-4.1672 0-7.5406-1.3891-3.3734-1.4552-5.7547-3.9026-2.3812-2.4474-3.7042-5.6885-1.2568-3.2411-1.2568-6.813 0-3.7042 1.2568-6.9453 1.2568-3.3073 3.638-5.7547 2.3812-2.5135 5.7547-3.9687 3.4396-1.4552 7.6729-1.4552 4.2333 0 7.5406 1.4552 3.3734 1.4552 5.6885 3.9026 2.3812 2.4474 3.5719 5.6885 1.2568 3.2411 1.2568 6.7469 0 0.8599-0.0661 1.6536 0 0.79375-0.13229 1.3229h-26.789q0.19844 2.0505 0.99219 3.638t2.0505 2.712 2.8443 1.7198 3.3073 0.59531q2.6458 0 4.9609-1.2568 2.3812-1.3229 3.2412-3.4396l7.6068 2.1167q-1.9182 3.9687-6.1516 6.5484-4.1672 2.5135-9.988 2.5135zm8.9958-20.902q-0.33073-3.9026-2.9104-6.2177-2.5135-2.3812-6.1516-2.3812-1.7859 0-3.3734 0.66146-1.5214 0.59531-2.712 1.7198t-1.9844 2.712q-0.72761 1.5875-0.8599 3.5057z"
                />
                <path
                    d="m386.44 72.76q-4.3656 0-8.5328-1.3891-4.1672-1.3891-7.1438-3.9687l3.3073-5.5562q3.175 2.249 6.1516 3.4396 3.0427 1.1245 6.0193 1.1245 2.6458 0 4.1672-0.99219 1.5214-0.99219 1.5214-2.8443 0-1.8521-1.7859-2.712-1.7859-0.8599-5.8208-1.9844-3.3734-0.92604-5.7547-1.7859t-3.8365-1.9182q-1.4552-1.1245-2.1167-2.5135-0.66146-1.4552-0.66146-3.4396 0-2.6458 0.99218-4.7625 1.0583-2.1167 2.9104-3.5719 1.8521-1.5214 4.2995-2.3151 2.5135-0.79375 5.424-0.79375 3.9026 0 7.276 1.1245 3.4396 1.1245 6.2838 3.638l-3.5719 5.3578q-2.6458-1.9844-5.1594-2.9104-2.4474-0.92604-4.8948-0.92604-2.249 0-3.7703 0.92604-1.5214 0.92604-1.5214 2.9766 0 0.92604 0.33073 1.5214 0.39688 0.59531 1.1906 1.0583 0.79375 0.46302 2.0505 0.92604 1.3229 0.39688 3.2411 0.8599 3.5719 0.92604 6.0854 1.8521 2.5797 0.92604 4.1672 2.1167 1.6536 1.1906 2.3812 2.7781 0.79375 1.5214 0.79375 3.7042 0 5.0932-3.7703 8.0698-3.7703 2.9104-10.253 2.9104z"
                />
                <path
                    d="m420.45 72.76q-4.3656 0-8.5328-1.3891-4.1672-1.3891-7.1437-3.9687l3.3073-5.5562q3.175 2.249 6.1516 3.4396 3.0427 1.1245 6.0193 1.1245 2.6458 0 4.1672-0.99219 1.5214-0.99219 1.5214-2.8443 0-1.8521-1.7859-2.712-1.7859-0.8599-5.8208-1.9844-3.3734-0.92604-5.7547-1.7859t-3.8365-1.9182q-1.4552-1.1245-2.1167-2.5135-0.66146-1.4552-0.66146-3.4396 0-2.6458 0.99219-4.7625 1.0583-2.1167 2.9104-3.5719 1.8521-1.5214 4.2995-2.3151 2.5135-0.79375 5.424-0.79375 3.9026 0 7.276 1.1245 3.4396 1.1245 6.2838 3.638l-3.5719 5.3578q-2.6458-1.9844-5.1594-2.9104-2.4474-0.92604-4.8948-0.92604-2.249 0-3.7703 0.92604-1.5214 0.92604-1.5214 2.9766 0 0.92604 0.33073 1.5214 0.39688 0.59531 1.1906 1.0583 0.79375 0.46302 2.0505 0.92604 1.3229 0.39688 3.2411 0.8599 3.5719 0.92604 6.0854 1.8521 2.5797 0.92604 4.1672 2.1167 1.6536 1.1906 2.3812 2.7781 0.79375 1.5214 0.79375 3.7042 0 5.0932-3.7703 8.0698-3.7703 2.9104-10.253 2.9104z"
                />
                <path
                    d="m208.43 95.978h-15.015v38.96h-9.062v-38.96h-15.015v-8.0036h39.092z"
                />
                <path
                    d="m223.1 135.6q-4.2333 0-7.6068-1.4552-3.3734-1.4552-5.7547-3.9026-2.3151-2.5135-3.5719-5.7547-1.2568-3.2412-1.2568-6.813 0-3.638 1.2568-6.8792 1.2568-3.2412 3.5719-5.6885 2.3812-2.5135 5.7547-3.9688 3.3734-1.4552 7.6068-1.4552t7.5406 1.4552q3.3734 1.4552 5.6885 3.9688 2.3812 2.4474 3.638 5.6885 1.2568 3.2411 1.2568 6.8792 0 3.5719-1.2568 6.813-1.2568 3.2411-3.5719 5.7547-2.3151 2.4474-5.6885 3.9026-3.3734 1.4552-7.6068 1.4552zm-9.062-17.926q0 2.3151 0.66146 4.2333 0.7276 1.8521 1.9182 3.2412 1.2568 1.3891 2.9104 2.1828 1.6536 0.7276 3.5719 0.7276 1.9182 0 3.5719-0.7276 1.6536-0.79375 2.8443-2.1828 1.2568-1.3891 1.9182-3.3073 0.7276-1.9182 0.7276-4.2333 0-2.249-0.7276-4.1672-0.66146-1.9182-1.9182-3.3073-1.1906-1.3891-2.8443-2.1167-1.6536-0.79375-3.5719-0.79375-1.9182 0-3.5719 0.79375-1.6536 0.79375-2.9104 2.1828-1.1906 1.3891-1.9182 3.3073-0.66146 1.9182-0.66146 4.1672z"
                />
                <path
                    d="m261.89 135.14q-3.5057 0-6.35-1.3891t-4.9609-3.7703q-2.0505-2.4474-3.175-5.6224-1.1245-3.175-1.1245-6.7469 0-3.7703 1.1906-7.0114 1.1906-3.2411 3.3073-5.6885 2.1167-2.4474 5.0932-3.8365 2.9766-1.3891 6.5484-1.3891 4.0349 0 7.0776 1.8521 3.0427 1.7859 5.0271 4.8286v-6.0854h7.7391v33.007q0 3.8364-1.4552 6.8792t-4.0349 5.1594q-2.5135 2.1167-6.0854 3.2412-3.5057 1.1245-7.6729 1.1245-5.6885 0-9.5911-1.9182-3.8365-1.8521-6.6146-5.2917l4.8286-4.6964q1.9844 2.4474 4.9609 3.8365 3.0427 1.3891 6.4161 1.3891 2.0505 0 3.9026-0.59531 1.9182-0.52917 3.3734-1.7198 1.4552-1.1906 2.249-3.0427 0.85989-1.8521 0.85989-4.3656v-4.3656q-1.7198 2.9766-4.8286 4.6302-3.1088 1.5875-6.6807 1.5875zm2.9766-7.0776q1.4552 0 2.7781-0.46302 1.3229-0.46302 2.4474-1.2568t1.9844-1.8521q0.85989-1.0583 1.3229-2.249v-8.2682q-1.1906-3.0427-3.7703-4.8948-2.5135-1.8521-5.2917-1.8521-2.0505 0-3.7042 0.92605-1.6536 0.85989-2.8443 2.3812-1.1906 1.4552-1.8521 3.3734-0.59531 1.9182-0.59531 3.9688 0 2.1167 0.7276 3.9688 0.72761 1.8521 1.9844 3.2411 1.3229 1.3891 3.0427 2.1828t3.7703 0.79375z"
                />
                <path
                    d="m307.58 135.6q-4.1672 0-7.5406-1.3891-3.3734-1.4552-5.7547-3.9026-2.3812-2.4474-3.7042-5.6885-1.2568-3.2412-1.2568-6.813 0-3.7042 1.2568-6.9453 1.2568-3.3073 3.638-5.7547 2.3812-2.5135 5.7547-3.9688 3.4396-1.4552 7.6729-1.4552t7.5406 1.4552q3.3734 1.4552 5.6885 3.9026 2.3812 2.4474 3.5719 5.6885 1.2568 3.2412 1.2568 6.7469 0 0.8599-0.0661 1.6536 0 0.79375-0.13229 1.3229h-26.789q0.19844 2.0505 0.99219 3.638t2.0505 2.712 2.8443 1.7198q1.5875 0.59531 3.3073 0.59531 2.6458 0 4.9609-1.2568 2.3812-1.3229 3.2411-3.4396l7.6068 2.1167q-1.9182 3.9687-6.1516 6.5484-4.1672 2.5135-9.988 2.5135zm8.9958-20.902q-0.33073-3.9026-2.9104-6.2177-2.5135-2.3812-6.1516-2.3812-1.7859 0-3.3734 0.66145-1.5214 0.59532-2.712 1.7198-1.1906 1.1245-1.9844 2.712-0.7276 1.5875-0.85989 3.5057z"
                />
                <path
                    d="m352.87 133.15q-1.7859 0.79375-4.3656 1.5875-2.5797 0.79375-5.424 0.79375-1.8521 0-3.5057-0.46303-1.5875-0.46302-2.8443-1.4552-1.1906-1.0583-1.9182-2.6458-0.72761-1.6536-0.72761-3.9688v-19.91h-4.5641v-6.813h4.5641v-11.245h8.8635v11.245h7.276v6.813h-7.276v16.933q0 1.8521 0.92604 2.6458 0.99219 0.7276 2.3812 0.7276t2.712-0.46302q1.3229-0.46302 2.1167-0.79375z"
                />
                <path
                    d="m390.96 134.94h-8.8635v-19.447q0-4.101-1.5214-6.0193-1.5214-1.9844-4.2995-1.9844-1.1906 0-2.5135 0.52916-1.3229 0.52917-2.5135 1.5214-1.1906 0.92604-2.1828 2.249-0.99218 1.3229-1.4552 2.9104v20.241h-8.8635v-48.286h8.8635v20.042q1.9182-3.3734 5.1594-5.1594 3.3073-1.8521 7.276-1.8521 3.3734 0 5.4901 1.1906 2.1167 1.1245 3.3073 3.0427 1.1906 1.9182 1.6536 4.3656 0.46302 2.4474 0.46302 5.0271z"
                />
                <path
                    d="m415.93 135.6q-4.1672 0-7.5406-1.3891-3.3734-1.4552-5.7547-3.9026-2.3812-2.4474-3.7042-5.6885-1.2568-3.2412-1.2568-6.813 0-3.7042 1.2568-6.9453 1.2568-3.3073 3.638-5.7547 2.3812-2.5135 5.7547-3.9688 3.4396-1.4552 7.6729-1.4552 4.2333 0 7.5406 1.4552 3.3734 1.4552 5.6885 3.9026 2.3812 2.4474 3.5719 5.6885 1.2568 3.2412 1.2568 6.7469 0 0.8599-0.0661 1.6536 0 0.79375-0.13229 1.3229h-26.789q0.19844 2.0505 0.99219 3.638t2.0505 2.712 2.8443 1.7198 3.3073 0.59531q2.6458 0 4.9609-1.2568 2.3812-1.3229 3.2412-3.4396l7.6068 2.1167q-1.9182 3.9687-6.1516 6.5484-4.1672 2.5135-9.988 2.5135zm8.9958-20.902q-0.33073-3.9026-2.9104-6.2177-2.5135-2.3812-6.1516-2.3812-1.7859 0-3.3734 0.66145-1.5214 0.59532-2.712 1.7198-1.1906 1.1245-1.9844 2.712-0.72761 1.5875-0.8599 3.5057z"
                />
                <path
                    d="m461.35 107.95q-4.0349 0.0661-7.2099 1.5875-3.175 1.4552-4.5641 4.4318v20.968h-8.8635v-34.66h8.1359v7.4083q0.92604-1.7859 2.1828-3.175 1.2568-1.4552 2.712-2.5135 1.4552-1.0583 2.9104-1.5875 1.5214-0.59532 2.9104-0.59532 0.7276 0 1.0583 0 0.39687 0 0.7276 0.06615z"
                />
            </g>
        </g>
    </svg>
</template>

<script setup></script>
