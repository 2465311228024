import { router } from "@inertiajs/vue3";

export default class User {
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    isAdmin() {
        if (!this.hasOwnProperty("role")) return undefined;

        return ["administrator", "superadmin"].includes(this.role);
    }

    isSuperAdmin() {
        if (!this.hasOwnProperty("role")) return undefined;

        return this.role === "superadmin";
    }

    matchesId(id) {
        return this.id === id;
    }

    get paths() {
        return {
            profile: route("staff.show", this.id),
        };
    }
}
