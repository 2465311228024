<template>
    <Dropdown>
        {{ menu.label }}
        <template #items>
            <DropdownItemGroup v-for="group in menu.items">
                <DropdownItem
                    v-for="item in group"
                    :href="getRoute(item.key, links)"
                    >{{ item.label }}</DropdownItem
                >
            </DropdownItemGroup>
        </template>
    </Dropdown>
</template>

<script setup>
import { inject } from "vue";
import Dropdown from "@/Shared/Dropdown.vue";
import DropdownItem from "@/Shared/DropdownItem.vue";
import DropdownItemGroup from "@/Shared/DropdownItemGroup.vue";
import { getRoute } from "@/Modules/navigation";

defineProps({
    menu: Object,
});

const links = inject("links");
</script>
