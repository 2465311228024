// window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

function getCsrfTokenFromCookie() {
    let cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("XSRF-TOKEN="));
    return cookie ? cookie.split("=")[1] : null;
}
const token = getCsrfTokenFromCookie();

if (token) {
    window.axios.defaults.headers.common["X-XSRF-TOKEN"] = token;
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

// window.Vue = require('vue');

//Vue Event Bus Object
// window.events = new Vue();

/**
 * Global Flash Helper
 *
 * flash('this message') creates a flash message
 * containing "this message"
 */
// window.flash = function (message) {
//     window.events.$emit('flash', message);
// }

window.flash = (data) =>
    window.dispatchEvent(
        new CustomEvent("flash", {
            detail: { title: data.title, body: data.body, type: data.type },
        })
    );

// window.flash = message => window.dispatchEvent(new CustomEvent('flash', {detail: {body: message}}))
