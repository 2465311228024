<template>
    <Disclosure as="nav" class="bg-blue-800 print:hidden" v-slot="{ open }">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-between">
                <div class="flex items-center">
                    <div class="flex-shrink-0">
                        <Link :href="links.home">
                            <BrandSvg />
                        </Link>
                    </div>
                    <div class="hidden lg:block">
                        <div class="ml-10 flex items-baseline space-x-4">
                            <template v-for="menu in navigation">
                                <NavMenu
                                    v-if="menu.hasOwnProperty('items')"
                                    :menu="menu"
                                />
                                <NavMenuButton
                                    v-else
                                    :href="getRoute(menu.key, links)"
                                    >{{ menu.label }}</NavMenuButton
                                >
                            </template>
                        </div>
                    </div>
                </div>
                <div class="hidden lg:block">
                    <div class="ml-4 flex items-center md:ml-6">
                        <!-- Notifications -->
                        <!-- <button type="button"
                            class="bg-blue-800 p-1 rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                            <span class="sr-only">View notifications</span>
                            <BellIcon class="h-6 w-6" aria-hidden="true" />
                        </button> -->

                        <!-- Profile dropdown -->
                        <Menu as="div" class="relative ml-3">
                            <div>
                                <MenuButton
                                    class="flex max-w-xs items-center rounded-full bg-blue-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-800"
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <!-- <img class="h-8 w-8 rounded-full" :src="user.imageUrl" alt="" /> -->
                                    <div class="group flex items-center">
                                        <div class="mr-3">
                                            <p
                                                class="text-sm font-medium leading-5 text-blue-300 group-hover:text-white"
                                            >
                                                {{ user.name }}
                                            </p>
                                        </div>
                                        <span
                                            class="hover:shadow-solid inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100"
                                        >
                                            <svg
                                                class="h-full w-full text-gray-400 group-hover:text-gray-300"
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </MenuButton>
                            </div>
                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                    <section>
                                        <DropdownItem
                                            v-for="item in userNavigation"
                                            :href="getRoute(item.key, links)"
                                            >{{ item.label }}</DropdownItem
                                        >
                                    </section>
                                    <MenuItem v-slot="{ active }">
                                        <Link
                                            :href="getRoute('logout', links)"
                                            method="post"
                                            as="button"
                                            :class="[
                                                active ? 'bg-blue-100' : '',
                                                'block w-full px-4 py-2 text-left text-sm text-gray-700',
                                            ]"
                                        >
                                            Logout</Link
                                        >
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
                <div class="-mr-2 flex lg:hidden">
                    <!-- Mobile menu button -->
                    <DisclosureButton
                        class="inline-flex items-center justify-center rounded-md bg-blue-800 p-2 text-blue-200 hover:bg-blue-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-800"
                    >
                        <span class="sr-only">Open main menu</span>
                        <MenuIcon
                            v-if="!open"
                            class="block h-6 w-6"
                            aria-hidden="true"
                        />
                        <XIcon
                            v-else
                            class="block h-6 w-6"
                            aria-hidden="true"
                        />
                    </DisclosureButton>
                </div>
            </div>
        </div>

        <DisclosurePanel class="lg:hidden">
            <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                <div class="space-y-4">
                    <template v-for="menu in navigation">
                        <NavMenu
                            v-if="menu.hasOwnProperty('items')"
                            :menu="menu"
                        />
                        <DisclosureButton
                            v-else
                            :as="NavMenuButton"
                            :href="getRoute(menu.key, links)"
                            v-text="menu.label"
                        ></DisclosureButton>
                    </template>
                </div>
            </div>
            <div class="border-t border-blue-700 pb-3 pt-4">
                <div class="flex items-center px-5">
                    <div class="flex-shrink-0">
                        <!-- Avatar -->
                        <span
                            class="hover:shadow-solid inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100"
                        >
                            <svg
                                class="h-full w-full text-gray-400 group-hover:text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                />
                            </svg>
                        </span>
                    </div>
                    <div class="ml-3">
                        <div class="text-base font-medium text-white">
                            {{ user.name }}
                        </div>
                        <div class="text-sm font-medium text-blue-300">
                            {{ user.email }}
                        </div>
                    </div>
                    <!-- <button type="button"
                        class="ml-auto bg-blue-800 flex-shrink-0 p-1 border-2 border-transparent rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                        <span class="sr-only">View notifications</span>
                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                    </button> -->
                </div>
                <div class="mt-3 space-y-1 px-2">
                    <DisclosureButton
                        v-for="item in userNavigation"
                        :key="item.label"
                        as="Link"
                        :href="getRoute(item.key, links)"
                        class="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-blue-500 hover:bg-opacity-75"
                    >
                        {{ item.label }}</DisclosureButton
                    >
                    <Link
                        :href="getRoute('logout', links)"
                        method="post"
                        class="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-blue-500 hover:bg-opacity-75"
                    >
                        Logout</Link
                    >
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup>
import { provide } from "vue";
import BrandSvg from "@/Shared/Svg/BrandSvg.vue";
import NavMenu from "@/Shared/Nav/Menus/NavMenu.vue";
import NavMenuButton from "@/Shared/Nav/Menus/NavMenuButton.vue";
import DropdownItem from "../DropdownItem.vue";

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/vue/outline";
import { useCurrentUser } from "@/Composables/useCurrentUser";
import { menus, getRoute } from "@/Modules/navigation";

const props = defineProps({
    links: Object,
});

provide("links", props.links);

const user = useCurrentUser();
const { navigation, userNavigation } = menus(user);
</script>
