/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Define global for dragula
window.global ||= window;

import { createApp, h } from "vue";
import { createInertiaApp, Head, Link } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import Layout from "@/Shared/Layouts/Stacked/Layout.vue";

createInertiaApp({
    resolve: (name) => {
        const page = resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        );
        page.then((module) => {
            module.default.layout = module.default.layout || Layout;
        });
        return page;
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .component("Link", Link)
            .component("Head", Head)
            .mount(el);
    },

    progress: {
        color: "#f59300",
        showSpinner: true,
    },

    title: (title) => `${title} - ST Staff`,
});

import "./bootstrap";
import "../css/app.css";

import moment from "moment";
window.moment = moment;
import Pikaday from "pikaday";
window.Pikaday = Pikaday;

/**
 * Import alpinejs and attach it to the window object
 */
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import mask from "@alpinejs/mask";
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(mask);

window.Alpine = Alpine;
Alpine.start();
