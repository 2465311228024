function adminMenus(user) {
    return [
        {
            label: "Series",
            items: [
                [
                    {
                        label: "Series",
                        key: "series",
                    },
                ],
                [
                    {
                        label: "Districts",
                        key: "districts",
                    },
                    {
                        label: "Sites",
                        key: "sites",
                    },
                ],
                [
                    {
                        label: "Meals",
                        key: "meals",
                    },
                    {
                        label: "Languages",
                        key: "languages",
                    },
                ],
            ],
        },
        {
            label: "Staff",
            items: [
                [
                    {
                        label: "Staff",
                        key: "staff",
                    },
                    {
                        label: "Staff Schedules",
                        key: "schedules",
                    },
                    {
                        label: "Job Assignments",
                        key: "assignments",
                    },
                ],
                [
                    {
                        label: "Timesheet Review",
                        key: "timesheets-review",
                    },
                    {
                        label: "Pay Periods",
                        key: "pay-periods",
                    },
                ],
                [
                    {
                        label: "Rates",
                        key: "rates",
                    },
                    {
                        label: "Role Categories",
                        key: "role-categories",
                    },
                ],
                [
                    {
                        label: "App Access",
                        key: "app-access",
                    },
                ],
            ],
        },
        {
            label: "Sessions",
            items: [
                [
                    {
                        label: "Sessions",
                        key: "sessions",
                        migrated: true,
                    },
                ],
                [
                    {
                        label: "Lessons",
                        key: "lessons",
                        migrated: true,
                    },
                    {
                        label: "Modules",
                        key: "modules",
                        migrated: true,
                    },
                ],
            ],
        },
        {
            label: "Invoicing",
            items: [
                [
                    {
                        label: "Invoices",
                        key: "invoices",
                    },
                ],
                [
                    {
                        label: "Funders",
                        key: "funders",
                    },
                    {
                        label: "Contracts",
                        key: "contracts",
                    },
                ],
            ],
        },
        {
            label: "My Info",
            items: [
                [
                    {
                        label: "My Schedule",
                        key: "my-schedule",
                    },
                    {
                        label: "Available Jobs",
                        key: "opportunities",
                    },
                ],
                [
                    {
                        label: "My Timesheets",
                        key: "timesheets",
                    },
                ],
            ],
        },
        {
            label: "App",
            items: [
                [
                    {
                        label: "Timeslots",
                        key: "timeslots",
                    },
                    {
                        label: "Service Periods",
                        key: "service-periods",
                    },
                ],
                [
                    {
                        label: "App Settings",
                        key: "settings",
                    },
                    {
                        label: "Changelog",
                        key: "changelog",
                    },
                ],
            ],
        },
    ];
}

function superAdminMenus(user) {
    return [
        ...adminMenus(user),
        {
            label: "Admin",
            items: [
                [
                    {
                        label: "Database Backups",
                        key: "database-backups",
                    },
                ],
            ],
        },
    ];
}

function userMenus(user) {
    return [
        {
            label: "References",
            items: [
                [
                    {
                        label: "Series",
                        key: "series",
                    },
                    {
                        label: "Sites",
                        key: "sites",
                    },
                    {
                        label: "Lessons",
                        key: "lessons",
                    },
                ],
            ],
        },
        { label: "Team", key: "staff" },
        {
            label: "Scheduling",
            items: [
                [
                    {
                        label: "Available Jobs",
                        key: "opportunities",
                    },
                    {
                        label: "My Schedule",
                        key: "my-schedule",
                    },
                    {
                        label: "All Sessions",
                        key: "sessions",
                    },
                ],
            ],
        },
        { label: "Timesheet", key: "timesheets" },
    ];
}

function userNavs(user) {
    let items = [
        {
            label: "My Profile",
            key: "profile",
        },
        { label: "App Changelog", key: "changelog" },
    ];

    if (user.isAdmin()) {
        items = [
            ...items,
            {
                label: "App Settings",
                key: "settings",
            },
        ];
    }

    return items;
}

export function menus(user) {
    let navigation = [];

    switch (user.role) {
        case "superadmin":
            navigation = superAdminMenus(user);
            break;
        case "administrator":
            navigation = adminMenus(user);
            break;
        default:
            navigation = userMenus(user);
    }
    return { navigation: navigation, userNavigation: userNavs(user) };
}

export function getRoute(key, links) {
    const keys = key.split(".");

    return keys.reduce((acc, key) => {
        if (acc && acc[key] !== undefined) {
            return acc[key];
        }
        return `/route-error/${key}#error`;
    }, links);
}
